import Vue from "vue";
import VueRouter from "vue-router";
import Logout from "@/components/Logout";
//import Login from "@/components/Login";
import LoadingPage from "@/views/LoadingPage";
import store from "@/store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: LoadingPage,
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/Home"),
  },
  {
    path: "/organisation",
    name: "organisations",
    component: () => import("@/components/org/Organisations.vue"),
  },
  {
    path: "/organisation/:organisationId",
    name: "OrgDetails",
    component: () => import("@/components/org/Organisation.vue"),
    props: true,
    beforeEnter(to, from, next) {
      const params = to.params;
      if (store.getters["auth/isMemberOf"](params.organisationId)) {
        next();
      } else {
        next({name: "organisations"});
      }
    },
  },
  {
    path: "/organisations/new",
    name: "OrgCreate",
    component: () => import("@/components/org/OrgCreate.vue"),
    props: true,
  },
  {
    path: "/submissions",
    name: "submissions",
    component: () => import("@/sg_copy/components/SubmissionTab"),
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
  },
  {
    path: "/sessionExpired",
    name: "sessionExpired",
    component: () => import("@/components/SessionExpired"),
    meta: {public: true},
  },
  {
    path: "/maintenance",
    name: "maintenance",
    component: () => import("@/views/Maintenance"),
    meta: {public: true},
  },
  {
    path: "/resetMfa/:userId/:key",
    name: "resetMfa",
    component: () => import("@/components/ResetMfa"),
    props: true,
    meta: {public: true},
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((routeTo, routeFrom, next) => {
  // Check if auth is required on this route
  // (including nested routes).
  const isPublic = routeTo.matched.some(route => route.meta.public);
  // If auth isn't required for the route, just continue.
  if (isPublic) return next();
  // If auth is required and the user is logged in...
  return store.dispatch("auth/validate").then(validUser => {
    // Then continue if the token still represents a valid user,
    // otherwise redirect to login.
    validUser ? next() : redirectToLogin();
  });

  function redirectToLogin() {
    // Pass the original route to the login component
    // TODO Temporarily commented out to avoid infinite loops. Should be enabled by a later ticket, but with lower priority.
    //next({name: "login", query: {redirectFrom: routeTo.fullPath}});
    window.location = "/sso/login/oidc";
  }
});

export default router;
