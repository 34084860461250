import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth.js";
import orgStore from "./orgStore";
import abnStore from "./abnStore";
import nzbnStore from "@/store/nzbnStore";
import joinRequestStore from "./joinRequestStore";
import role from "./roleStore";
import configStore from "./configStore";
import applyStore from "@/store/applyStore";
import hereMapsStore from "@/store/hereMapsStore";
import userStore from "./userStore";
import mySubmissionStore from "@/store/mySubmissionStore";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    orgStore,
    abnStore,
    nzbnStore,
    joinRequestStore,
    role,
    configStore,
    applyStore,
    hereMapsStore,
    mySubmissionStore,
    userStore,
  },
  strict: process.env.NODE_ENV !== "production",
});
// Strict mode is enabled in development only to detect inappropriate access and mutation of the state.
// This is done with a watcher that is very resource intensive, so we disable the watcher in production
